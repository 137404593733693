
import { defineComponent } from "vue";
import PhoneCard from "./PhoneCard/index.vue";
export default defineComponent({
  components: { PhoneCard },
  setup() {
    return {
      appName: "海经院校安保",
      msglist: [
        "海经院校安保是一款针对海口经济学院定制的安全系统，内含校园新闻资讯、平安校园、教师查寝、车辆查询等模块内容，目标是让学校安保系统更完善，学生更安全！",
      ],
      moduleList: [
        {
          imgurl: require("../../assets/prod-xab/phon1-index.png"),
          title: "首页·新闻资讯",
          msg: "APP分为教师端和学生端，不同身份用户权限级别不同， 可查看内容也不相同，提高用户体验性。",
          order: true,
        },
        {
          imgurl: require("../../assets/prod-xab/phon2-paxy.png"),
          title: "平安校园",
          msg: "对海口经济学院定制全区域监控覆盖，让安全遍布每一个角落 不同用户可根据自身权限观看不同区域监控视频",
          order: false,
        },
        {
          imgurl: require("../../assets/prod-xab/phon3-cq.png"),
          title: "查寝",
          msg: "不同权限用户可根据搜索条件精准查询班级、楼栋、 学生对出入寝情况。",
          order: true,
        },
        {
          imgurl: require("../../assets/prod-xab/phon4-clcx.png"),
          title: "车辆查询",
          msg: "监控进出入校园的车辆，对不识别车牌车辆严格审查，进一步确保校园安全！",
          order: false,
        },
      ],
    };
  },
});
